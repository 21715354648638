<!--<template>
    <div class="features-area mt-minus-40 pb-75" id="features">
        <div class="container">
            <div class="section-title">
                <span class="sub-title purple-color">Key Features</span>
                <h2 class="nunito-font">Most probably included best features ever</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-features-item style-two">
                        <div class="icon">
                            <i class="flaticon-comment"></i>
                        </div>
                        <h3 class="nunito-font">Live Chat Handover HO</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-features-item style-two">
                        <div class="icon">
                            <i class="flaticon-analytics"></i>
                        </div>
                        <h3 class="nunito-font">Sentiment Analysis </h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-features-item style-two">
                        <div class="icon">
                            <i class="flaticon-artificial-intelligence"></i>
                        </div>
                        <h3 class="nunito-font">Artificial Intelligence</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed est non feugiat feu sagittis, donec.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>
-->

<template>
    <div class="overview-area ptb-100" id="features">
        <div class="container">
            
            <div class="overview-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-image">
                        <img src="../../assets/images/services-we-offer.png" data-aos="fade-up" alt="overview">
                    </div>
                    <div class="col-lg-6 col-md-12 overview-content">
                        <span class="sub-title">Features</span>
                        <h2>We are here, to help your fieldforce</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Complete Work Order Management
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Geo-fencing with forced GPS
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Dynamic Forms Creation
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Image Recognition for PPE kit
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="overview-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-content">
                        <span class="sub-title">Features</span>
                        <h2>Best fieldforce app for your company.</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Intelligent Ticketing System
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Offline Working with local database
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Escalation Matrix with Work flows
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Face Recognition for employees

                                </span>
                            </li>
                           
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-12 overview-image">
                        <!--<img src="../../assets/images/services.png" data-aos="fade-up" alt="overview">-->
                        <img src="../../assets/images/img-77.png" data-aos="fade-up" alt="overview">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'Services'
}
</script>
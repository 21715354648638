<template>
    <div class="testimonials-area bg-1d2b53 ptb-100">
        <div class="container">
            <div class="section-title white-color">
                <span class="sub-title">Testimonials</span>
                <h2>Here’s what our amazing clients are saying</h2>
            </div>
            <div class="testimonials-slides">
                <carousel
                    :autoplay="5000"
                    :wrap-around="true"
                >
                    <Slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="single-testimonials-item">
                            <p>{{slide.desc}}</p>
                            <div class="client-info d-flex align-items-center justify-content-center">
                                <!--<img :src="slide.image" alt="user">-->
                                <div class="info">
                                    <h3>{{slide.name}}</h3>
                                    <span>{{slide.position}}</span>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <Pagination />
                    </template> 
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                desc: 'Zippy Valet parking made my evening an absolute breeze! I recently attended a fancy event at a crowded venue and was worried about finding a parking spot. However, the valet parking service was a game-changer. Upon arrival, a friendly valet attendant greeted me with a smile, took my car keys, and parked my car for me. It was such a relief not to stress about finding a parking space or walking a long distance to the event. When the event ended, I simply had to request my car, and within minutes, it was brought to me, all warm and ready to go. The convenience and efficiency of valet parking truly exceeded my expectations.',
                /*image: require('../../assets/images/user/user1.jpg'),*/
                name: 'Aniket Sharma',
                
            },
            {
                id: 2,
                desc: "Zippy Valet parking provided an exceptional and stress-free experience that I can't help but rave about! I recently visited a busy downtown area with limited parking options, and the valet service turned my outing into a smooth and enjoyable one. Upon arrival, I was warmly greeted by the valet attendants who were incredibly courteous and professional. They made sure to explain the entire process and assured me that my car would be well taken care of. Handing over my keys felt like entrusting my vehicle to a responsible friend.",
                /*image: require('../../assets/images/user/user2.jpg'),*/
                name: 'Akash Agarwal',
                /*position: 'Python Developer',*/
            },
            {
                id: 3,
                desc: "I had the pleasure of experiencing Zippy valet parking recently, and it completely changed the way I approach outings and events! From the moment I arrived at the venue, the valet attendants were attentive and friendly, making me feel like a valued guest. The convenience of valet parking is unmatched. I didn't have to worry about finding a parking spot in a crowded area or walking long distances in uncomfortable shoes. The valet attendants took care of everything, allowing me to focus on enjoying the event to the fullest.",
                /*image: require('../../assets/images/user/user3.jpg'),*/
                name: 'Isha Mehta',
                /*position: 'PQA Developer',*/
            },
            
        ],
    }),
})
</script>
<template>
   
    <div class="contact-area ptb-100 pb0" id="contact">
        <div class="container">
            <div class="section-title style-two">
                <span class="sub-title">Contact with us</span>
                <h2>Let's Talk!</h2>
            </div>
            <div class="row">
            <div class="col-lg-6 col-md-12 template-footer-one">
                <img src="../../assets/images/contact.png" data-aos="fade-up" alt="overview">
            		<div class="single-footer-widget">
                    <div class="box">
                    <ul class="contact-section-list mb0">
                        <li><i class="ph-phone-call"></i><h6>Phone</h6><a href="tel:+1-485-456-0102">+91 033 4004 0711</a></li>
                        <li><i class="ph-envelope-simple"></i><h6>Email</h6><a href="mailto:hello@vumy.com">hello@zippy.com</a></li>
                        <li><i class="ph-map-pin"></i><h6>Address</h6> AE 711 Sector 1, Salt Lake City, Kolkata - 700064 </li>
                    </ul>
                    </div>  
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 template-footer-one">
                   <div class="single-footer-widget">
                    <div class="contact-form box" id="contact-form">
                        <p v-if="error.length">
                        <b>Please correct the following errors:</b>
                        <ul class="form-error-contact">
                           <li v-for="e in error" v-bind:key="e.id">
	                      {{e}}
                          </li>
                        </ul>
</p>
                        <form  @submit="contactF">
                            
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Your Name*</label>
                                        <input type="text" name="name" class="form-control" v-model="nameF"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Email Address*</label>
                                        <input type="email" name="email" class="form-control" v-model="email"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Subject</label>
                                        <input type="text" name="subject" class="form-control" v-model="subject"> 
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input type="text" name="phone" class="form-control" v-model="phone">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label>Message...</label>
                                        <textarea name="message" id="message" class="form-control" cols="30" rows="6" ></textarea>
                                    </div>
                                </div>
                               
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <input type="submit" class="btn-style-one red-light-color" value="Send Message"/> 
                                </div>
                            </div>
                        </form>
                    </div>
                   </div> 
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
export default{
   name:"contactF",
   data()
   {
      return{
	    error:[],
        email:null,
	    subject:null,
   	    phone:null
	   }
   }, methods:{
       contactF(e)
	  {   
              this.error=[];
              if(this.nameF && this.email && this.subject && this.phone)
	      {
                console.warm("no error")
               }
	      if(!this.nameF)
              {
		      this.error.push("Name is required");
	          }	
              if(!this.email)
              {
		      this.error.push("Email address is required");
	          }	    
        
	      console.warn("Hello",this.error);
	      e.preventDefault();	
	   }

    }
}
</script>


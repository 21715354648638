<template>
    <div class="section-title" id="benefits">
                <span class="sub-title purple-color">Key Benefits</span>
                <h2 class="nunito-font">Benefits that Zippy provides</h2>
            </div>
            <div class="features-area pb-75">
        <div class="container">
          
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="features-box">
                        <div class="icon">
                            <i class="flaticon-nature"></i>
                        </div>
                        <h3 class="nunito-font">Real Time Data</h3>
                        <p>All Photos are timestamped with no Photo Upload option from Gallery. In-built Image compression included.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="features-box">
                        <div class="icon">
                            <i class="flaticon-parking"></i>
                        </div>
                        <h3 class="nunito-font">OTP Authentication</h3>
                        <p>Role Based Login with OTP validation</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="features-box">
                        <div class="icon">
                            <i class="flaticon-shield"></i>
                        </div>
                        <h3 class="nunito-font">Faster Deployment</h3>
                        <p>Compleate Master and USER Role Management</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="features-box">
                        <div class="icon">
                            <i class="flaticon-nature"></i>
                        </div>
                        <h3 class="nunito-font">Push Notifications</h3>
                        <p>Push notifications for all predefined events</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="features-box">
                        <div class="icon">
                            <i class="flaticon-parking"></i>
                        </div>
                        <h3 class="nunito-font">Easy to Use</h3>
                        <p>Clean UI with simple navigation for easy adoption on the field</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="features-box">
                        <div class="icon">
                            <i class="flaticon-shield"></i>
                        </div>
                        <h3 class="nunito-font">Scalable and Secure</h3>
                        <p>Cloud-ready platform ready for scalability with complete security</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="goal-area with-top-border ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="1200">
                    <div class="goal-image">
                        <img src="../../assets/images/our-goal.png" alt="goal-image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="goal-content purple-color">
                        <span class="sub-title">Results</span>
                        <h2 class="nunito-font">Corporates who have deployed Zippy are reaping the benefits of : </h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <i class="flaticon-draw-check-mark"></i>
                                Higher Operational efficiency 
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <i class="flaticon-draw-check-mark"></i>
                                Increased productivity. 
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                Reduced administrative costs 
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <i class="flaticon-draw-check-mark"></i>
                                A transparent and collaborative work environment 
                            </li>
                        </ul>
                       <!-- <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                            <router-link to="/contact" class="btn-style-one purple-color">
                                Get Started Now 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurGoal'
}
</script>
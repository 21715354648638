<template>
    <div class="overview-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="overview-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-image">
                        <img src="../../assets/images/overview/overview1.png" data-aos="fade-up" alt="overview">
                    </div>
                    <div class="col-lg-6 col-md-12 overview-content">
                        <span class="sub-title">Why Choose Us?</span>
                        <h2>We are here, to help your business</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    High integrity, with over 100 satisfied clients.
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Our team is trained in UX methodologies and we follow the best practises that are defined.
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Digital Transformation to optimize your business
                                </span>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </div>
            <div class="overview-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-content">
                        <span class="sub-title">Our Goal</span>
                        <h2>Our Goal - Provide seamless and care-free parking experience to customers</h2>
                        <ul class="overview-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Car pickup and drop requests made easy
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Hassle-free parking
                                </span>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <span>
                                    <i class="flaticon-draw-check-mark"></i>
                                    Increased safety
                                </span>
                            </li>
                            
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-12 overview-image">
                        <img src="../../assets/images/overview/overview2.png" data-aos="fade-up" alt="overview">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>
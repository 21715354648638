<template>
    <div class="testimonials-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title yellow-color">Testimonials </span>
                <h2 class="nunito-font">Here’s what our amazing Students are saying </h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="testimonials-box">
                        <div class="rating">
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Pellentesque felis nisl ut laoreet euismod vel, integer.</p>
                        <div class="info d-flex align-items-center">
                            <img src="../../assets/images/user/user1.jpg" alt="user">
                            <div class="title">
                                <h3>Lora Joly</h3>
                                <span>Founder at Envato</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="testimonials-box">
                        <div class="rating">
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Pellentesque felis nisl ut laoreet euismod vel, integer.</p>
                        <div class="info d-flex align-items-center">
                            <img src="../../assets/images/user/user2.jpg" alt="user">
                            <div class="title">
                                <h3>David Warner</h3>
                                <span>Founder at ThemeForest</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="testimonials-box">
                        <div class="rating">
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                            <i class="ph-star"></i>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna facilisi viverra felis eleifend ornare urna. Eu mauris, velit volutpat massa volutpat. Pellentesque felis nisl ut laoreet euismod vel, integer.</p>
                        <div class="info d-flex align-items-center">
                            <img src="../../assets/images/user/user3.jpg" alt="user">
                            <div class="title">
                                <h3>James Anderson</h3>
                                <span>Founder at EnvyTheme</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestimonialsFive'
}
</script>
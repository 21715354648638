<template>
    <div class="working-process-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-content">
                        <span class="sub-title">Our Working Process</span>
                        <h2>Dedicated to design and develop customised applications</h2>
                        <ul class="working-process-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <div class="number">
                                    1
                                </div>
                                <h3>Requirement Analysis</h3>
                                <p>We determine the needs and expectations of a new product.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="number">
                                    2
                                </div>
                                <h3>Application UI design</h3>
                                <p>We design interactive and user-friendly UI for applications</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div class="number">
                                    3
                                </div>
                                <h3>Research and development </h3>
                                <p>We study and analyse the user requirements and develop applications for use across Android and iOS devices</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-image bg1">
                        <img src="../../assets/images/working-process.jpg" alt="working-process">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurWorkingProcess'
}
</script>
<template>
    <div class="preloader-area">
        <div class="preloader">
            <div class="loader">
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="waviy">
                <span>Z</span>
                <span>I</span>
                <span>P</span>
                <span>P</span>
                <span>Y</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Preloader'
}
</script>